import { graphql, Link, navigate } from 'gatsby';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import React from 'react';
import { CloseIcon } from '../components/SVGIcons';
import TemplateWrapper from '../components/TemplateWrapper';
import WorkInfo from '../components/WorkInfo';
import WorkModalContent from '../components/WorkModalContent';
import WorkContext from '../utils/WorkContext';

export default ({ data, location }) => {
  const prevPath = (location.state && location.state.prevPath) ? location.state.prevPath : false;
  const workContext = React.useContext(WorkContext);

  const handleNavigation = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget || event.key === 'Escape') {
      navigate(prevPath ? prevPath : '/');
    }
  };

  const getAspectRatioClass = () => {
    // Select predefined class based on CMS data
    switch (data.datoCmsWork.aspectRatio) {
      case 'Wide':
        return 'modal--wide'
      case 'Retro':
        return 'modal--retro'
      case 'Square':
        return 'modal--square'
      default:
        return 'modal--regular'
    }
  }

  if (workContext.showDirectorScreen || workContext.showContactScreen) {
    navigate('/');
  }

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div className='work-wrapper'>
          {modal ? (
            <div className="work-modal__wrapper">
              <WorkModalContent
                project={data.datoCmsWork}
                modal
                className={getAspectRatioClass()}
              />
              <WorkInfo project={data.datoCmsWork} />
            </div>
          ) : (
              <TemplateWrapper className='page-work' >
                <HelmetDatoCms seo={data.datoCmsWork.seoMetaTags} />
                <Link
                  to={prevPath ? prevPath : '/'}
                  className='work-modal__wrapper'
                  onClick={handleNavigation}
                  onKeyUp={handleNavigation}
                  role='dialog'
                >
                  <WorkModalContent
                    project={data.datoCmsWork}
                    modal
                    closeTo={closeTo}
                    className={getAspectRatioClass()}
                  />
                  <WorkInfo project={data.datoCmsWork} />
                </Link>
              </TemplateWrapper>
            )}
          <div className="work-close">
            <CloseIcon clickHandler={() => {navigate(prevPath ? prevPath : '/')}} />
          </div>
        </div>
      )}
    </ModalRoutingContext.Consumer>
  );
};

export const query = graphql`
  query WorkQuery($slug: String!) {
    datoCmsWork(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subHeading
      byline
      videoId
      info
      runTime
      year
      category
      client
      location
      aspectRatio
      coverImage {
        url
        fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
  }
`;
