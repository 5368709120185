import fscreen from 'fscreen';
import { isIOSDevice } from 'ios-detector';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { FullscreenIcon, PauseIcon, PlayIcon, VolumeIcon, VolumeMuteIcon } from './SVGIcons';

const WorkModalContent = ({ project, className }) => {
  const playerEl = useRef(null)
  const iosPlayerEl = useRef(null)
  let controlFadeTimer = null
  const [playerState, setPlayerState] = useState({
    playing: false,
    started: false,
    controls: false,
    volume: 1,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playsinline: true,
  });
  
  const [iosPlayerState, setIosPlayerState] = useState({
    playing: false,
    muted: true,
  })

  const [showInfoInModal, setShowInfoInModal] = useState(false);
  const [controlVisibity, setControlVisibity] = useState(true)
  const [isIOS, setIsIOS] = useState(null)
  const [isIOSFullscreen, setIsIOSFullscreen] = useState(null)


  const handlePlayback = (e) => {
    if (isIOSFullscreen) {
      setIsIOSFullscreen(false)
      setIosPlayerState({
        playing: false,
        muted: true
      })
      playerEl.current.seekTo(parseFloat(e.target.value));
    }
    setPlayerState({ ...playerState, ...{ playing: !playerState.playing } });
  };
  
  const handleStart = () => {
    setPlayerState({ ...playerState, ...{ started: true } });
  };

  const handleSeekMouseDown = (e) => {
    setPlayerState({ ...playerState, ...{ seeking: true } });
  };

  const handleSeekChange = (e) => {
    setPlayerState({
      ...playerState,
      ...{ played: parseFloat(e.target.value) },
    });
  };

  const handleSeekMouseUp = (e) => {
    setPlayerState({ ...playerState, ...{ seeking: false } });
    playerEl.current.seekTo(parseFloat(e.target.value));
  };

  const handleProgress = (state) => {
    setPlayerState({ ...playerState, ...state });
  };

  const handleMute = (e) => {
    setPlayerState({ ...playerState, ...{ muted: !playerState.muted } });
  };

  const handleControlFade = (event) => {
    // If it's a mouse event
    if (event) {
      if (event.type === 'mouseleave' && playerState.playing) {
        setControlVisibity(false)
        return
      }

      if (!controlVisibity) {
        setControlVisibity(true)
      }
    } else {
      // When the media starts playing
      controlFadeTimer = setTimeout(() => {
        setControlVisibity(false)
      }, 3000);
    }
  }

  const handleFullscreen = () => {
    if (fscreen.fullscreenEnabled) {
      fscreen.requestFullscreen(playerEl.current.wrapper)
    } else if (isIOS) {
      iosPlayerEl.current.seekTo(playerState.played)
      setIosPlayerState({
        playing: true,
        muted: false,
      })
      setIsIOSFullscreen(true)
    }
  }

  useEffect(() => {
    if (isIOS === null) {
      setIsIOS(isIOSDevice())
      setPlayerState({ ...playerState, ...{ playing: !isIOSDevice() } });
    }

    if (isIOSFullscreen) {
      setPlayerState({ ...playerState, ...{ playing: false } });
    }

    return () => {
      clearTimeout(controlFadeTimer)
    }
  }, [isIOSFullscreen])

  return (
    <div className={`modal__video-wrapper ${className}`}>
      <div
        className={`c-modal__inner ${controlVisibity ? '' : 'control--fade' }`}
        onMouseEnter={handleControlFade} 
        onMouseMove={handleControlFade}
        onMouseLeave={handleControlFade}
      >

        <div 
          className="sheet__video-wrapper">
          <div className='c-control__wrapper'>
            {playerState.playing ? (
              <PauseIcon
                clickHandler={handlePlayback}
                className={`c-control-icon ${playerState.started ? '' : 'icon--faded'}`}
              />
            ) : (
              <PlayIcon
                clickHandler={handlePlayback}
                className='c-control-icon'
              />
            )}
            {playerState.muted ? (
              <VolumeMuteIcon
                clickHandler={handleMute}
                className='c-control-icon'
              />
            ) : (
              <VolumeIcon clickHandler={handleMute} className='c-control-icon' />
            )}
            <input
              type='range'
              min={0}
              max={0.999999}
              step='any'
              value={playerState.played}
              className='c-control-icon'
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
            />
            <FullscreenIcon clickHandler={handleFullscreen} />
          </div>
          {isIOS && !playerState.started && !playerState.playing && (
            <p onClick={handlePlayback} className='c-control-icon--text' >Tap to play</p>
          )}
          <span onClick={handlePlayback} className='c-control__faux-layer'></span>
          <ReactPlayer
            className='react-player'
            url={`https://player.vimeo.com/video/${project.videoId}`}
            loop
            playing={playerState.playing}
            volume={playerState.volume}
            muted={playerState.muted}
            playsInline={playerState.playsinline}
            controls={false}
            width='100%'
            height='100%'
            config={{
              vimeo: {
                playerOptions: { autoplay: false, controls: false, byline: false, playsinline: true },
                preload: true,
              },
            }}
            ref={playerEl}
            onProgress={handleProgress}
            onPlay={handleControlFade}
            onStart={handleStart}
            onPause={() => setControlVisibity(true)} />
            {isIOS && (
              <ReactPlayer
              className='react-player react-player--ios'
              url={`https://player.vimeo.com/video/${project.videoId}`}
              ref={iosPlayerEl}
              playing={iosPlayerState.playing}
              volume={playerState.volume}
              muted={iosPlayerState.muted}
              playsInline={false}
              controls={false}
              width='100%'
              height='100%'
              config={{
                vimeo: {
                  playerOptions: { autoplay: false, controls: false, byline: false, playsinline: false },
                  preload: true,
                },
              }}
              onProgress={handleProgress}
              onPlay={handleControlFade}
              onStart={handleStart}
              onPause={() => setControlVisibity(true)} />
            )}
        </div>
      </div>
      <div className="project__info-mobile">
        <h1 className='work__title'>{project.title}</h1>
        {project.subHeading && (
          <p className="work-entry-subtitle">{project.subHeading}</p>
        )}
        <div className='work__info-wrapper' dangerouslySetInnerHTML={{ __html: project.info }} />
      </div>
    </div>
  );
};

export default WorkModalContent;