import React, { useEffect, useRef, useState } from 'react';
import { ArrowIcon } from './SVGIcons';

const WorkInfo = ({ project }) => {
  const {title, info} = project;
  const [isInfoMode, setIsInfoMode] = useState(false);
  const [infoBarOffset, setInfoBarOffset] = useState(0);
  const [isEventAttached, setIsEventAttached] = useState(false);
  const infoWrapperEl = useRef(null);
  const infoEntryEl = useRef(null);

  const handleClickToClose = (event) => {
    if ( event.target.classList.contains('container__body-content') ) {
      setInfoBarOffset(infoEntryEl.current ? infoEntryEl.current.scrollHeight : 0);  
      setIsInfoMode(false);
      document.body.classList.remove('work-tab-info');
      window.removeEventListener('click', handleClickToClose);
      setIsEventAttached(false);
    }
  }

  const handleDirectorInfoToggle = (event) => {
    if (isInfoMode === false) {
      setInfoBarOffset(infoWrapperEl.current.scrollHeight);
      setIsInfoMode(true);
      window.addEventListener('click', handleClickToClose);
      document.body.classList.add('work-tab-info');
      setIsEventAttached(true);
    } else {
      setInfoBarOffset(infoEntryEl.current.scrollHeight);  
      setIsInfoMode(false);
      document.body.classList.remove('work-tab-info');
      window.removeEventListener('click', handleClickToClose);
      setIsEventAttached(false);
    }
  }

  useEffect(() => {
    setInfoBarOffset(infoEntryEl.current.scrollHeight);

    // Remove transition delay from infoWrapper
    setTimeout(() => {
      if (infoWrapperEl.current) {
        infoWrapperEl.current.style.transitionDelay = '0s';
      }
    }, 500);

    return () => {
      if (isEventAttached) {
        window.removeEventListener('click', handleClickToClose);
        setIsEventAttached(false);
      }
    }
  }, [])
  
  return (
    <div className={`work-info__wrap ${isInfoMode ? 'credits' : 'video'}`} ref={infoWrapperEl} style={{ transform: `translateY(-${infoBarOffset}px` }}>
      <div className="work-entry" ref={infoEntryEl}>
        <div className="work-entry-info">
          <h6 className="work-entry-title">{title}</h6>
          {project.subHeading && (
            <p className="work-entry-subtitle">{project.subHeading}</p>
          )}
          </div>
        <div className="work-entry-toggle">
          <span className={isInfoMode === 'active' ? 'video' : ''} onClick={handleDirectorInfoToggle}>
            info <ArrowIcon className={isInfoMode ? '' : 'open'} />
          </span>
        </div>
      </div>
      <div className="work-info">
        <div className="work-intro" dangerouslySetInnerHTML={{
          __html: info,
        }}></div>
      </div>
    </div>
  )
}

export default WorkInfo;